.heading {
  color: white;
  opacity: 0.9;
}

.text {
  color: white;
  opacity: 0.9;
  font-size: 1.3rem;
  text-align: center;
  margin: 0.5rem;
}
